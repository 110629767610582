import "./App.css";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules"; // Burayı kontrol edin

// Import Swiper styles
import "swiper/css";
import Ui1 from "./components/ui-1";
import Ui2 from "./components/ui-2";
import Ui3 from "./components/ui-3";
import Ui4 from "./components/ui-4";
import Ui5 from "./components/ui-5";

// import required modules

function App() {
  const [data, setData] = useState(null);

  const path = window.location.search;
  const urlParams = new URLSearchParams(path);

  const queryParams = {};

  // Tüm query parametrelerini döngüye alın ve objeye ekleyin
  for (const [key, value] of urlParams) {
    queryParams[key] = value;
  }

  console.log("queryParams ", queryParams);
  const get = () => {
    console.log("Get fonksiyonu cagrildi ");

    let url = `https://ziraatbankasi24-${queryParams?.app}-cmsapi.eventapp.app/api/posts-screen`;

    if (
      queryParams.app === undefined ||
      queryParams?.app === "1" ||
      Number(queryParams?.app) > 5
    )
      url = `https://ziraatbankasi24-cmsapi.eventapp.app/api/posts-screen`;

    // if (queryString === "1") {
    // }

    console.log("url ", url);
    // `https://ziraatbankasi${queryString}-cmsapi.eventapp.app/api/posts-screen`

    // "https://dev-cmsapi.iciletisim.app/api/posts-screen"

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.result);
        setData(data.result);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    get();
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     // refreshCount durumunu artırarak bileşeni güncelle
  //     window.location.reload();
  //   }, 21000); // 5 saniyede bir

  //   // useEffect temizleme fonksiyonu
  //   return () => clearInterval(intervalId);
  // }, []); // Boş bağımlılık dizisi, sadece bir kere çalışmasını sağlar

  return (
    <>
      {(queryParams?.ui === "1" ||
        queryParams.ui === undefined ||
        Number(queryParams?.ui) > 5) && (
          <Ui1 data={data} get={get} />
        )}

      {/* 2000 x 3500 */}
      {queryParams?.ui === "2" && (
        <>
          <Ui2 data={data} get={get} />
        </>
      )}
      {/* 2200 x 3500 */}
      {queryParams?.ui === "3" && (
        <Ui3 data={data} get={get} />
      )}

      {/* 2000 x 3700 */}
      {queryParams?.ui === "4" && (
        <Ui4 data={data} get={get} />
      )}
      {/* 288 x 480 */}
      {queryParams?.ui === "5" && (
        <Ui5 data={data} get={get} />
      )}

      {/* v1 */}
      {queryParams?.ui === "old" && (
        <header className="App-header">
          <div>
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper"
              onSlideChange={(e) => {
                console.log("realIndex", e.realIndex);
                if (e.realIndex >= data.length - 1) {
                  console.log("DATA ÇEKİLDİ");
                  get();
                }
              }}
            >
              {data?.map((item, index) => {
                return (
                  <SwiperSlide key={index * 3}>
                    <div className="post-card">
                      <div className="header">
                        <div className="profile">
                          <img
                            src={
                              item?.attendee_profile_img &&
                                !item.attendee_profile_img.includes("avatar")
                                ? item?.attendee_profile_img
                                : "/avatar.svg"
                            }
                            alt="profile"
                            className="profile-image"
                          />
                        </div>
                        <div className="user-info">
                          <strong style={{ display: "block" }}>
                            {item?.userName ?? ""}
                          </strong>
                          <b className="post-time">
                            {moment(item?.created).format("DD.MM.YYYY HH:mm") ??
                              "---"}
                          </b>
                        </div>
                      </div>
                      <p className="hashtag">
                        {item?.hashtags?.map((hashtag) => {
                          return (
                            <>
                              <span style={{ color: "#007bff" }}>
                                {hashtag}
                              </span>
                            </>
                          );
                        })}
                      </p>

                      <div className="content-image">
                        <img
                          className="post"
                          src={
                            item.medias?.length > 0 ? item?.medias[0].thumb : ""
                          }
                          alt="post"
                        />
                      </div>

                      <div className="post-bottom">
                        <div className="post-like">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="post-heart"
                          >
                            <path d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z" />
                          </svg>
                          <p className="bold">
                            {item?.likes?.length ?? 0} Beğeni
                          </p>
                        </div>

                        <div className="post-like">
                          <svg
                            className="post-comment"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M256 32C114.6 32 .0272 125.1 .0272 240c0 47.63 19.91 91.25 52.91 126.2c-14.88 39.5-45.87 72.88-46.37 73.25c-6.625 7-8.375 17.25-4.625 26C5.818 474.2 14.38 480 24 480c61.5 0 109.1-25.75 139.1-46.25C191.1 442.8 223.3 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32zM256.1 400c-26.75 0-53.12-4.125-78.38-12.12l-22.75-7.125l-19.5 13.75c-14.25 10.12-33.88 21.38-57.5 29c7.375-12.12 14.37-25.75 19.88-40.25l10.62-28l-20.62-21.87C69.82 314.1 48.07 282.2 48.07 240c0-88.25 93.25-160 208-160s208 71.75 208 160S370.8 400 256.1 400z" />
                          </svg>
                          <p className="bold">
                            {item?.comments?.length ?? 0} Yorum
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </header>
      )}

          {/* {queryParams?.ui === "2" && (
        <>
        <div className="w-full h-screen !max-h-[2500px] overflow-hidden !bg-gray-200">
          <Swiper
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            modules={[Autoplay]}
            className="mySwiper"
            onSlideChange={(e) => {
              console.log("realIndex", e.realIndex);
              if (e.realIndex >= data.length - 1) {
                console.log("DATA ÇEKİLDİ");
                get();
              }
            }}
          >
            {data?.map((item, index) => {
              return (
                <SwiperSlide key={index * 3}>
                  <div className="">
                    <div className="flex border-[5px] border-white rounded-3xl shadow justify-center items-center mb-5 mt-5">
                      <div className="mx-9">
                        <img
                          src={
                            item?.attendee_profile_img &&
                            !item.attendee_profile_img.includes("avatar")
                              ? item?.attendee_profile_img
                              : "/avatar.svg"
                          }
                          alt="profile"
                          className="w-[205px] h-[215px] rounded-full"
                        />
                      </div>
                      <div className="text-7xl pt-1 px-5">
                        <strong style={{ display: "block" }}>
                          {item?.userName ?? ""}
                        </strong>
                        <b className="text-5xl text-center">
                          {moment(item?.created).format("DD.MM.YYYY HH:mm") ??
                            "---"}
                        </b>
                      </div>
                    </div>
                    <p className="block">
                      {item?.hashtags?.map((hashtag) => {
                        return (
                          <>
                            <span style={{ color: "#007bff" }}>{hashtag}</span>
                          </>
                        );
                      })}
                    </p>

                    <div className="relative">
                      <div className="flex items-center justify-center min-h-[2000px] mt-11">
                        <img
                          className="!w-full h-full !max-h-[2000px] overflow-hidden  object-contain rounded-3xl"
                          src={
                            item.medias?.length > 0 ? item?.medias[0].thumb : ""
                          }
                          alt="post"
                        />
                      </div>
                    </div>

                    <div className="text-black bg-inherit text-7xl pb-5 -mt-3 pt-7 bottom-0 relative">
                      <div className="flex justify-center border-[5px] bg-inherit border-inherit mx-9 rounded-3xl py-12 px-2 shadow-md">
                        <div className="flex bg-inherit ml-5">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="w-[75px] h-[75px] bg-inherit"
                            fill="#fff"
                          >
                            <path d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z" />
                          </svg>
                          <p className="bold bg-inherit text-white mx-5">
                            {item?.likes?.length ?? 0} Beğeni
                          </p>
                        </div>

                        <div className="flex bg-inherit ml-12">
                          <svg
                            className="w-[75px] h-[75px] bg-inherit"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            fill="#fff"
                          >
                            <path d="M256 32C114.6 32 .0272 125.1 .0272 240c0 47.63 19.91 91.25 52.91 126.2c-14.88 39.5-45.87 72.88-46.37 73.25c-6.625 7-8.375 17.25-4.625 26C5.818 474.2 14.38 480 24 480c61.5 0 109.1-25.75 139.1-46.25C191.1 442.8 223.3 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32zM256.1 400c-26.75 0-53.12-4.125-78.38-12.12l-22.75-7.125l-19.5 13.75c-14.25 10.12-33.88 21.38-57.5 29c7.375-12.12 14.37-25.75 19.88-40.25l10.62-28l-20.62-21.87C69.82 314.1 48.07 282.2 48.07 240c0-88.25 93.25-160 208-160s208 71.75 208 160S370.8 400 256.1 400z" />
                          </svg>
                          <p className="bold bg-inherit text-white mx-5">
                            {item?.comments?.length ?? 0} Yorum
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        </>

      )} */}
    </>
  );
}

export default App;
