import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";


const Ui4 = ({ data, get }) => {
  return (
    <div className="w-[2200px] h-[3700px] !max-w-[2200px] !max-h-[3700px] overflow-hidden !bg-gray-200">
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        modules={[Autoplay]}
        className="mySwiper"
        onSlideChange={(e) => {
          console.log("realIndex", e.realIndex);
          if (e.realIndex >= data.length - 1) {
            console.log("DATA ÇEKİLDİ");
            get();
          }
        }}
      >
        {data?.map((item, index) => {
          return (
            <SwiperSlide key={index * 3}>
              <div className="">
                <div className="flex border-[7px] border-white rounded-3xl mx-3 py-3 shadow justify-center items-center mb-5 mt-5">
                  <div className="mx-9 px-7 ml-1">
                    <img
                      src={
                        item?.attendee_profile_img &&
                          !item.attendee_profile_img.includes("avatar")
                          ? item?.attendee_profile_img
                          : "/avatar.svg"
                      }
                      alt="profile"
                      className="w-[195px] h-[205px] rounded-full py-2"
                    />
                  </div>
                  <div className="text-8xl pt-1 px-5 font-bold">
                    <strong style={{ display: "block" }}>
                      {item?.userName ?? ""}
                    </strong>
                    {/* <b className="text-7xl text-center">
                          {moment(item?.created).format("DD.MM.YYYY HH:mm") ??
                            "---"}
                        </b> */}
                  </div>
                </div>
                <p className="block">
                  {item?.hashtags?.map((hashtag) => {
                    return (
                      <>
                        <span style={{ color: "#007bff" }}>{hashtag}</span>
                      </>
                    );
                  })}
                </p>

                <div className="relative">
                  <div className="flex items-center justify-center min-h-[3150px] mt-11">
                    <img
                      className="!w-full h-full !max-h-[3150px] overflow-auto  object-contain rounded-3xl"
                      src={
                        item.medias?.length > 0 ? item?.medias[0].thumb : ""
                      }
                      alt="post"
                    />
                  </div>
                </div>

                <div className="text-black bg-inherit text-8xl font-bold pb-5 -mt-3 pt-7 bottom-0 relative">
                  <div className="flex justify-center border-[7px] bg-inherit border-inherit mx-9 rounded-3xl py-12 px-2 shadow-md">
                    <div className="flex bg-inherit ml-5 pl-5 pr-5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        className="w-[110px] h-[110px] bg-inherit"
                        fill="#fff"
                      >
                        <path d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z" />
                      </svg>
                      <p className="bold bg-inherit text-white mx-5">
                        {item?.likes?.length ?? 0} Beğeni
                      </p>
                    </div>

                    {/* <div className="flex bg-inherit ml-7 pl-5 pr-5">
                          <svg
                            className="w-[110px] h-[110px] bg-inherit"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            fill="#fff"
                          >
                            <path d="M256 32C114.6 32 .0272 125.1 .0272 240c0 47.63 19.91 91.25 52.91 126.2c-14.88 39.5-45.87 72.88-46.37 73.25c-6.625 7-8.375 17.25-4.625 26C5.818 474.2 14.38 480 24 480c61.5 0 109.1-25.75 139.1-46.25C191.1 442.8 223.3 448 256 448c141.4 0 255.1-93.13 255.1-208S397.4 32 256 32zM256.1 400c-26.75 0-53.12-4.125-78.38-12.12l-22.75-7.125l-19.5 13.75c-14.25 10.12-33.88 21.38-57.5 29c7.375-12.12 14.37-25.75 19.88-40.25l10.62-28l-20.62-21.87C69.82 314.1 48.07 282.2 48.07 240c0-88.25 93.25-160 208-160s208 71.75 208 160S370.8 400 256.1 400z" />
                          </svg>
                          <p className="bold bg-inherit text-white mx-5">
                            {item?.comments?.length ?? 0} Yorum
                          </p>
                        </div> */}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  )
}

export default Ui4